import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { auth } from './firebase';
import './assets/scss/style.scss';

Vue.config.productionTip = false;

new Vue({
	router,
	render: (h) => h(App),
}).$mount('#app');

let app;
auth.onAuthStateChanged(() => {
	if (!app) {
		app = new Vue({
			router,
			render: (h) => h(App),
		}).$mount('#app');
	}
});
