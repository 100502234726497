<template>
  <section class="section">
    <div class="container">
      <h1 class="title">Admin: A Slice of Keesh</h1>
      <div class="columns">
        <div class="column buttons">
          <button class="button is-primary" @click="updatePostCategories">
            Update Post Categories
          </button>
          <button class="button is-primary" @click="updatePopularPosts">
            Update Popular Posts
          </button>
          <button class="button is-primary" @click="updateLatestPosts">
            Update Latest Posts
          </button>
          <button class="button is-primary" @click="updateSubscribersList">
            Update Subscriber List
          </button>
        </div>
        <div class="column buttons has-text-right">
          <button class="button is-primary" @click="popUp('newCategory')">
            Add New Category
          </button>
        </div>
      </div>
      <h2 class="subtitle">Subscribers: {{ subscribers ? subscribers.length : 0 }}</h2>
      <h2 class="subtitle">Posts</h2>
      <div class="columns">
        <div class="column">
          <div
            v-for="post of posts"
            :key="post.title"
            :class="post.published ? 'card mb-5' : 'card mb-5 unpublished'"
          >
            <div class="card-content is-flex is-justify-content-space-between">
              <p class="title is-5 mb-0 is-flex is-align-items-center">
                <span
                  class="tag is-light is-medium mr-3"
                  :title="post.comment_count + ' comments'"
                  >{{ post.comment_count }}</span
                >
                <span class="tag is-link is-light is-medium mr-3" :title="post.likes + ' likes'">{{
                  post.likes
                }}</span>
                {{ post.title }}
              </p>
              <div class="is-flex is-justify-content-space-between">
                <p class="buttons">
                  <a :href="'/edit-post/' + post.id" class="button is-primary">
                    Edit
                  </a>
                  <button @click="deletePost(post.id, post.title)" class="button is-primary">
                    Delete
                  </button>
                  <button
                    v-if="post.published && !post.sent_subscribers"
                    @click="sharePost(post.id, post.title)"
                    class="button is-primary"
                  >
                    Share
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-flex is-justify-content-space-between">
          <button class="button is-primary" @click="createPost">
            New Post
          </button>
          <button class="button" @click="logout">Log Out</button>
        </div>
      </div>
      <h2 class="subtitle">Requests</h2>
      <div class="columns">
        <div class="column">
          <div v-for="request of requests" :key="request.id" class="card mb-2">
            <div class="card-content p-2">
              <p class="has-text-weight-bold is-flex is-align-items-center">
                {{ request.name }} - {{ request.email }}
              </p>
              <p>{{ request.request }}</p>
            </div>
          </div>
        </div>
      </div>
      <h2 class="subtitle">Comments</h2>
      <div class="columns">
        <div class="column">
          <div v-for="comment of comments" :key="comment.id" class="card mb-2">
            <div class="card-content p-2">
              <p class="has-text-weight-bold is-flex is-align-items-center">
                {{ comment.name }} -
                <a
                  :href="
                    'mailto:' +
                      comment.email +
                      '?subject=Slice of Keesh - Comment Reply&body=Hi%20 ' +
                      comment.name
                  "
                  >{{ comment.email }}</a
                >
                - {{ comment.date.toLocaleString() }}
              </p>
              <p>{{ comment.comment }}</p>
              <p>
                <a :href="'https://sliceofkeesh.com/post/' + comment.slug" target="_blank">{{
                  comment.post
                }}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="'modal ' + (popUps.newCategory ? 'is-active' : '')">
      <div class="modal-background"></div>
      <div class="modal-content">
        <div class="box">
          <div class="fields">
            <div class="field">
              <label class="label">Category Name</label>
              <div class="control">
                <input class="input" type="text" v-model="newCategory.name" />
              </div>
            </div>

            <div class="field">
              <label class="label">Slug</label>
              <div class="control">
                <input class="input" type="text" v-model="newCategory.slug" />
              </div>
            </div>

            <button class="button is-primary is-fullwidth" @click="addCategory">
              Add Category
            </button>
          </div>
        </div>
      </div>
      <button
        class="modal-close is-large"
        aria-label="close"
        @click="popUp('newCategory')"
      ></button>
    </div>
  </section>
</template>

<script>
import router from '../router/index';
import {
  auth,
  call,
  subscribers,
  aggregates,
  categories,
  posts,
  requests,
  fieldValue,
} from '../firebase';
import { updateLatestPosts, updatePopularPosts } from '@/helpers';

export default {
  name: 'Index',
  data() {
    return {
      posts: [],
      requests: [],
      subscribers: null,
      popUps: {
        newCategory: false,
      },
      newCategory: {
        name: '',
        slug: '',
        posts: [],
      },
    };
  },
  computed: {
    comments: function() {
      if (!this.posts) return [];
      let posts = this.posts;
      let comments = [];
      for (let post of posts) {
        for (let comment of post.latest_comments) {
          let date = new Date(0);
          date.setUTCSeconds(comment.timestamp.seconds);
          comments.push({
            post: post.title,
            slug: post.slug,
            date,
            ...comment,
          });
        }
      }
      comments = comments.sort((a, b) => (a.timestamp.seconds > b.timestamp.seconds ? -1 : 1));
      console.log(comments);
      return comments;
    },
  },
  methods: {
    addCategory: async function() {
      try {
        await categories.add(this.newCategory);
        this.newCategory.name = '';
        this.newCategory.slug = '';
        await this.updateCategories();
        alert('Saved!');
      } catch (error) {
        console.error(error.message);
      }
    },
    popUp: function(modal) {
      this.popUps[modal] = !this.popUps[modal];
    },
    createPost: async function() {
      const post = {
        title: '',
        slug: '',
        blurb: '',
        image: '',
        content: '',
        categories: [],
        published: false,
        likes: 0,
        comment_count: 0,
        latest_comments: [],
        publish_date: fieldValue.serverTimestamp(),
        created_date: fieldValue.serverTimestamp(),
        updated_date: fieldValue.serverTimestamp(),
        sent_subscribers: false,
      };

      try {
        const doc = await posts.add(post);
        router.push('/edit-post/' + doc.id);
      } catch (error) {
        console.error(error.message);
      }
    },
    deletePost: async function(id, name) {
      const areYouSure = confirm('Are you sure you want to delete the post ' + name);
      if (areYouSure) {
        try {
          await posts.doc(id).delete();
          location.reload();
        } catch (error) {
          console.error(error.message);
        }
      }
    },
    sharePost: async function(id, name) {
      const areYouSure = confirm('Are you sure you want to share this post: ' + name);
      if (areYouSure) {
        try {
          const post = this.posts.find((post) => post.id === id);
          const data = {
            link: `https://sliceofkeesh.com/post/${post.slug}`,
            title: post.title,
            content: post.blurb,
            image: `https://firebasestorage.googleapis.com/v0/b/slice-of-keesh.appspot.com/o/posts%2F${post.slug}%2F${post.image}?alt=media`,
          };
          const sendNewPost = call.httpsCallable('sendNewPost');
          const result = await sendNewPost(data);
          if (result.data.success) {
            console.log(`Shared ${post.title}!`);
            await posts.doc(id).set({ sent_subscribers: true }, { merge: true });
          }
        } catch (error) {
          console.error(error.message);
        }
      }
    },
    updateCategories: async function() {
      try {
        const allCategories = await categories.get();
        let category_list = { categories: [] };
        allCategories.forEach(async (categoryDoc) => {
          const data = categoryDoc.data();
          category_list.categories.push({
            name: data.name,
            slug: data.slug,
          });
        });
        aggregates.doc('category_list').update(category_list);
        console.log('Categories updated!');
      } catch (error) {
        console.error(error);
      }
    },
    updatePostCategories: async function() {
      try {
        const allCategories = await categories.get();
        const allPosts = await posts.get();
        allPosts.forEach(async (postDoc) => {
          const post = postDoc.data();
          if (post.slug !== 'about' && post.slug !== 'resources' && post.published) {
            allCategories.forEach(async (categoryDoc) => {
              if (post.categories.includes(categoryDoc.data().name)) {
                await categoryDoc.ref.update({
                  posts: fieldValue.arrayUnion(postDoc.id),
                });
              } else {
                await categoryDoc.ref.update({
                  posts: fieldValue.arrayRemove(postDoc.id),
                });
              }
            });
          }
        });
        console.log('Updated post categories!');
      } catch (error) {
        console.error(error);
      }
    },
    updatePopularPosts: async function() {
      updatePopularPosts();
    },
    updateLatestPosts: async function() {
      updateLatestPosts();
    },
    updateSubscribersList: async function() {
      try {
        const allSubscribers = await subscribers.get();
        let subscriber_list = { subscribers: [] };
        allSubscribers.forEach(async (subscriberDoc) => {
          const data = subscriberDoc.data();
          subscriber_list.subscribers.push({
            email: data.email,
            first_name: data.first_name,
            joined: data.joined,
          });
        });
        aggregates.doc('all_subscribers').update(subscriber_list);
        console.log('Updated subscriber list!');
      } catch (error) {
        console.error(error);
      }
    },
    logout: async function() {
      try {
        await auth.signOut();
        router.push('/login');
      } catch (error) {
        console.error(error.message);
      }
    },
  },
  created: async function() {
    const snapshotPosts = await posts.orderBy('updated_date', 'desc').get();
    snapshotPosts.forEach((doc) => {
      this.posts.push({ id: doc.id, ...doc.data() });
    });

    const snapshotRequests = await requests.orderBy('timestamp', 'desc').get();
    snapshotRequests.forEach((doc) => {
      this.requests.push({ id: doc.id, ...doc.data() });
    });

    let subscribers = await aggregates.doc('all_subscribers').get();
    subscribers = subscribers.data().subscribers;
    this.subscribers = subscribers;
  },
};
</script>
