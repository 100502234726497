import Vue from 'vue';
import VueRouter from 'vue-router';
import Index from '../views/Index.vue';
import { getCurrentUser } from '../firebase';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Index',
		component: Index,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/login',
		name: 'Login',
		component: () =>
			import(/* webpackChunkName: "login" */ '../views/Login.vue'),
	},
	{
		path: '/edit-post/:slug',
		name: 'Edit Post',
		component: () =>
			import(/* webpackChunkName: "editpost" */ '../views/EditPost.vue'),
		meta: {
			requiresAuth: true,
		},
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach(async (to, from, next) => {
	const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
	const currentUser = await getCurrentUser();
	const isKeshia = currentUser?.email === 'keshia.com@gmail.com';
	if (requiresAuth && !isKeshia) {
		next('/login');
	} else {
		next();
	}
});

export default router;
