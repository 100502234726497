import firebase from '@firebase/app';
import '@firebase/firestore';
import '@firebase/functions';
import '@firebase/auth';

const firebaseConfig = {
	apiKey: 'AIzaSyD_LTkXSCMMPaPlm5MT4aOGoqUjuMhZ4EM',
	authDomain: 'slice-of-keesh.firebaseapp.com',
	projectId: 'slice-of-keesh',
	storageBucket: 'slice-of-keesh.appspot.com',
	messagingSenderId: '33130015649',
	appId: '1:33130015649:web:a2c8fe9f89d5375aaf39e0',
	measurementId: 'G-74JM67ZT2X',
};

firebase.initializeApp(firebaseConfig);

const getCurrentUser = () => {
	return new Promise((resolve, reject) => {
		const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
			unsubscribe();
			resolve(user);
		}, reject);
	});
};

const db = firebase.firestore();
const call = firebase.functions();
const auth = firebase.auth();

const provider = new firebase.auth.GoogleAuthProvider();
const subscribers = db.collection('subscribers');
const aggregates = db.collection('aggregates');
const requests = db.collection('requests');
const posts = db.collection('posts');
const categories = db.collection('categories');
const fieldValue = firebase.firestore.FieldValue;
const timestamp = firebase.firestore.Timestamp;
const increment = firebase.firestore.FieldValue.increment(1);
const decrement = firebase.firestore.FieldValue.increment(-1);

export {
	db,
	auth,
	call,
	provider,
	getCurrentUser,
	subscribers,
	aggregates,
	requests,
	posts,
	categories,
	timestamp,
	fieldValue,
	increment,
	decrement,
};
