import { posts, aggregates } from './firebase';

const ignore = ['about', 'resources', 'privacy'];

const updateLatestPosts = async () => {
	try {
		const snapshot = await posts
			.where('published', '==', true)
			.orderBy('publish_date', 'desc')
			.limit(10)
			.get();
		let latestPosts = [];
		snapshot.forEach(async (doc) => {
			const data = doc.data();
			console.log(data.slug, !ignore.includes(data.slug));
			if (!ignore.includes(data.slug)) {
				latestPosts.push({ id: doc.id, ...doc.data() });
			}
		});
		aggregates.doc('latest_posts').set({
			posts: latestPosts,
		});
		console.log('Updated Latest Posts!');
	} catch (error) {
		console.error(error);
	}
};

const updatePopularPosts = async () => {
	try {
		const snapshot = await posts
			.where('published', '==', true)
			.orderBy('publish_date', 'desc')
			.get();
		let popularPosts = [];
		snapshot.forEach(async (doc) => {
			const data = doc.data();
			if (!ignore.includes(data.slug)) {
				const popularity = data.likes + data.comment_count;
				popularPosts.push({ id: doc.id, ...data, popularity });
			}
		});
		popularPosts = popularPosts.sort((a, b) =>
			a.popularity > b.popularity ? -1 : 1
		);
		popularPosts = popularPosts.slice(0, 3); // Top 3 posts
		aggregates.doc('popular_posts').set({
			posts: popularPosts,
		});
		console.log('Updated Popular Posts!');
	} catch (error) {
		console.log(error);
	}
};

export { updateLatestPosts, updatePopularPosts };
